<template>
    <div>
        <section>
            <h1 class="page-heading" v-html="resource[lang].heading" />
            <p class="text" v-html="resource[lang].paragraph_1" />
            <p class="text" v-html="resource[lang].paragraph_2" />
        </section>

        <MatrixForm disable />
        <MatrixSequences disable />
    </div>
</template>

<script>
import resource from '@/assets/resources/planungsmatrix-resource'

import MatrixForm from '@/components/forms/MatrixForm'
import MatrixSequences from '@/components/MatrixSequences'

export default {
    components: {
        MatrixForm,
        MatrixSequences
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    }
}
</script>

<style lang="scss" scoped>

section {
    max-width: 75ch;

    margin-inline-start: 10%;
    margin-inline-end: auto;

    margin-block-end: 5rem;
}

.wrapper {
    margin-inline-start: 5%;
    margin-inline-end: 5%;
    margin-block-end: 5rem;
}

p + p {
    margin-top: 1rem;
}

@media (max-width: 50rem) {
  section {
      padding-inline-end: 2rem;
  }
}

</style>